import en from './i18n/en';
import fr from './i18n/fr';
import pt from '/i18n/pt';

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  messages: {
    en,
    fr,
    pt,
  },
}));

export default {
  generic: {
    appName: 'Nectar',
    email: 'E-mail',
    otpCode: 'Code à usage unique',
    password: 'Mot de passe',
    username: "Nom d'utilisateur",
    firstname: 'Prénom',
    lastname: 'Nom',
    birthdate: 'Date de naissance',
    lang: 'Langue',
    website: 'Site web',
    logout: 'Me déconnecter',
    save: 'Sauvegarder',
    height: 'Taille',
    weight: 'Poids',
    sleep: 'Heures de sommeil',
    gender: {
      male: 'Homme',
      female: 'Female',
    },
    day: '0 jour | {count} jour  | {count} jours',
    years: '{years} an | {years} an  | {years} ans',
    updatedAt: 'Dernière mise à jour: {date}',
    add: 'Ajouter',
  },
  validations: {
    duplicate: 'Cet e-mail existe déjà',
    email: 'Veuillez saisir une adresse e-mail valide.',
    generic: 'Ce champ est obligatoire.',
    invalidEmail:
      "Format non valide, le format de l'e-mail devrait être exemple",
    invalidUrl:
      "Format non valide, le format de l'URL devrait être https://www.exemple.com",
    maxCharacters: 'caractères max',
    picture: {
      dimensions:
        "L'image est trop large, veuillez sélectionner un fichier de moins de {maxSize}",
      size: "L'image est trop grande, veuillez sélectionner un fichier de moins de",
      type: "Format non valide, le format de l'image devrait être *.jpg ou *.png.",
    },
    required: 'Ce champ est requis.',
    url: "Format non valide, le format de l'URL devrait être https://www.exemple.com",
    valid: 'doit être valide',
    minLength: 'Ce champs doit contenir au moins {min} caractères.',
    minValue: 'Ce champs doit avoir une valeur supérieure ou égale à {min}.',
    sameAsPassword: 'Le mot de passe doit être similaire.',
  },
  homePage: {
    heading: 'Bievenue sur Nectar, veuillez vous connecter ☀️',
    cta: 'Me connecter',
  },
  signInPage: {
    welcome: {
      heading: '✨ Bienvenue sur Nectar',
      description:
        'La solution SaaS de nutrition au service des professionnels de la santé et du bien-être',
    },
    heading: 'Connection',
    cta: 'Me connecter',
    form: {
      email: {
        placeholder: 'Votre adresse e-mail',
      },
      password: {
        placeholder: 'Votre mot de passe',
      },
      otpCode: {
        placeholder: 'ex: 111222',
      },
    },
  },
  dashboardPage: {
    heading: 'Dashboard',
    sidePanel: {
      menu: {
        home: {
          label: 'Accueil',
        },
        account: {
          label: 'Mon compte',
        },
        patient: {
          label: 'Mes patients',
        },
      },
    },
    accountPage: {
      heading: 'Mon compte',
    },
  },
  page: {
    consultation: {
      objectives: {
        'weight-loss': 'Perte de poids',
        'gain-muscle-mass': 'Gain de masse musculaire',
        'improved-physical-performance':
          'Amélioration des performances physiques',
        'improved-energy-and-vitality':
          "Amélioration de l'énergie et de la vitalité",
        'management-of-medical-conditions': 'Gestion des conditions médicales',
        'digestive-health': 'Santé digestive',
        'transition-to-specific-diet': 'Transition vers un régime spécifique',
        'nutrition-education': 'Éducation nutritionnelle',
      },
    },
  },
  modal: {
    foodUpsert: {
      create: {
        title: 'Créer un aliment',
      },
      update: {
        title: 'Mettre à jour un aliment',
      },
    },
    patientUpsert: {
      create: {
        title: 'Créer un patient',
      },
      update: {
        title: 'Mettre à jour un patient',
      },
    },
    portionUpsert: {
      create: {
        title: 'Créer une portion',
      },
      update: {
        title: 'Mettre à jour une portion',
      },
    },
  },
  onboarding: {
    patient: {
      regime: {
        isVegan: 'Vegan',
        isVegetarian: 'Végétarien',
        isPorkFree: 'Sans porc',
        isFishFree: 'Sans poisson ni fruit de mer',
        isSugarFree: 'Sans sucre',
        isGlutenFree: 'Sans gluten',
        isLactoseFree: 'Sans lactose',
      },
    },
  },
};

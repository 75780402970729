export default {
  generic: {
    appName: 'Nectar',
    email: 'Email',
    otpCode: 'One-time code',
    password: 'Password',
    username: 'Username',
    firstname: 'First name',
    lastname: 'Last name',
    birthdate: 'Date of birth',
    lang: 'Language',
    website: 'Website',
    logout: 'Log out',
    save: 'Save',
    height: 'Height',
    weight: 'Weight',
    sleep: 'Sleep hours',
    gender: {
      male: 'Male',
      female: 'Female',
    },
    years: '{years} year | {years} year | {years} years',
    updatedAt: 'Last updated: {date}',
    add: 'Add',
  },
  validations: {
    duplicate: 'This email already exists',
    email: 'Please enter a valid email address.',
    generic: 'This field is required.',
    invalidEmail:
      'Invalid format, the email format should be example@example.com',
    invalidUrl:
      'Invalid format, the URL format should be https://www.example.com',
    maxCharacters: 'max characters',
    picture: {
      dimensions:
        'The image is too wide, please select a file less than {maxSize}',
      size: 'The image is too large, please select a file less than',
      type: 'Invalid format, the image format should be *.jpg or *.png.',
    },
    required: 'This field is required.',
    url: 'Invalid format, the URL format should be https://www.example.com',
    valid: 'must be valid',
    minLength: 'This field must contain at least {min} characters.',
    minValue: 'This field must have a value greater than or equal to {min}.',
    sameAsPassword: 'The password must be similar.',
  },
  homePage: {
    heading: 'Welcome to Nectar, please log in ☀️',
    cta: 'Log in',
  },
  signInPage: {
    welcome: {
      heading: '✨ Welcome to Nectar',
      description:
        'The SaaS nutrition solution for health and wellness professionals',
    },
    heading: 'Login',
    cta: 'Log in',
    form: {
      email: {
        placeholder: 'Your email address',
      },
      password: {
        placeholder: 'Your password',
      },
      otpCode: {
        placeholder: 'e.g., 111222',
      },
    },
  },
  dashboardPage: {
    heading: 'Dashboard',
    sidePanel: {
      menu: {
        home: {
          label: 'Home',
        },
        account: {
          label: 'My account',
        },
        patient: {
          label: 'My patients',
        },
      },
    },
    accountPage: {
      heading: 'My account',
    },
  },
  page: {
    consultation: {
      objectives: {
        'weight-loss': 'Weight loss',
        'gain-muscle-mass': 'Muscle mass gain',
        'improved-physical-performance': 'Improved physical performance',
        'improved-energy-and-vitality': 'Improved energy and vitality',
        'management-of-medical-conditions': 'Management of medical conditions',
        'digestive-health': 'Digestive health',
        'transition-to-specific-diet': 'Transition to a specific diet',
        'nutrition-education': 'Nutrition education',
      },
    },
  },
  modal: {
    foodUpsert: {
      create: {
        title: 'Create a food',
      },
      update: {
        title: 'Update a food',
      },
    },
    patientUpsert: {
      create: {
        title: 'Create a patient',
      },
      update: {
        title: 'Update a patient',
      },
    },
    portionUpsert: {
      create: {
        title: 'Create a portion',
      },
      update: {
        title: 'Update a portion',
      },
    },
  },
  onboarding: {
    patient: {
      regime: {
        isVegan: 'Vegan',
        isVegetarian: 'Vegetarian',
        isPorkFree: 'Pork-free',
        isFishFree: 'Fish and seafood-free',
        isSugarFree: 'Sugar-free',
        isGlutenFree: 'Gluten-free',
        isLactoseFree: 'Lactose-free',
      },
    },
  },
};

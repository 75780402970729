export default {
  generic: {
    appName: 'Nectar',
    email: 'E-mail',
    otpCode: 'Código de uso único',
    password: 'Senha',
    username: 'Nome de usuário',
    firstname: 'Nome',
    lastname: 'Sobrenome',
    birthdate: 'Data de nascimento',
    lang: 'Idioma',
    website: 'Site',
    logout: 'Sair',
    save: 'Salvar',
    height: 'Altura',
    weight: 'Peso',
    sleep: 'Horas de sono',
    gender: {
      male: 'Masculino',
      female: 'Feminino',
    },
    years: '{years} ano | {years} ano | {years} anos',
    updatedAt: 'Última atualização: {date}',
    add: 'Adicionar',
  },
  validations: {
    duplicate: 'Este e-mail já existe',
    email: 'Por favor, insira um endereço de e-mail válido.',
    generic: 'Este campo é obrigatório.',
    invalidEmail:
      'Formato inválido, o formato do e-mail deve ser exemplo@exemplo.com',
    invalidUrl:
      'Formato inválido, o formato da URL deve ser https://www.exemplo.com',
    maxCharacters: 'caracteres máximos',
    picture: {
      dimensions:
        'A imagem é muito larga, selecione um arquivo menor que {maxSize}',
      size: 'A imagem é muito grande, selecione um arquivo menor que',
      type: 'Formato inválido, o formato da imagem deve ser *.jpg ou *.png.',
    },
    required: 'Este campo é obrigatório.',
    url: 'Formato inválido, o formato da URL deve ser https://www.exemplo.com',
    valid: 'deve ser válido',
    minLength: 'Este campo deve conter pelo menos {min} caracteres.',
    minValue: 'Este campo deve ter um valor maior ou igual a {min}.',
    sameAsPassword: 'A senha deve ser semelhante.',
  },
  homePage: {
    heading: 'Bem-vindo ao Nectar, por favor faça login ☀️',
    cta: 'Entrar',
  },
  signInPage: {
    welcome: {
      heading: '✨ Bem-vindo ao Nectar',
      description:
        'A solução SaaS de nutrição para profissionais de saúde e bem-estar',
    },
    heading: 'Login',
    cta: 'Entrar',
    form: {
      email: {
        placeholder: 'Seu endereço de e-mail',
      },
      password: {
        placeholder: 'Sua senha',
      },
      otpCode: {
        placeholder: 'ex: 111222',
      },
    },
  },
  dashboardPage: {
    heading: 'Painel',
    sidePanel: {
      menu: {
        home: {
          label: 'Início',
        },
        account: {
          label: 'Minha conta',
        },
        patient: {
          label: 'Meus pacientes',
        },
      },
    },
    accountPage: {
      heading: 'Minha conta',
    },
  },
  page: {
    consultation: {
      objectives: {
        'weight-loss': 'Perda de peso',
        'gain-muscle-mass': 'Ganho de massa muscular',
        'improved-physical-performance': 'Melhoria do desempenho físico',
        'improved-energy-and-vitality': 'Melhoria da energia e vitalidade',
        'management-of-medical-conditions': 'Gestão de condições médicas',
        'digestive-health': 'Saúde digestiva',
        'transition-to-specific-diet': 'Transição para uma dieta específica',
        'nutrition-education': 'Educação nutricional',
      },
    },
  },
  modal: {
    foodUpsert: {
      create: {
        title: 'Criar um alimento',
      },
      update: {
        title: 'Atualizar um alimento',
      },
    },
    patientUpsert: {
      create: {
        title: 'Criar um paciente',
      },
      update: {
        title: 'Atualizar um paciente',
      },
    },
    portionUpsert: {
      create: {
        title: 'Criar uma porção',
      },
      update: {
        title: 'Atualizar uma porção',
      },
    },
  },
  onboarding: {
    patient: {
      regime: {
        isVegan: 'Vegano',
        isVegetarian: 'Vegetariano',
        isPorkFree: 'Sem porco',
        isFishFree: 'Sem peixe nem frutos do mar',
        isSugarFree: 'Sem açúcar',
        isGlutenFree: 'Sem glúten',
        isLactoseFree: 'Sem lactose',
      },
    },
  },
};
